
import { Component, Vue, Prop } from 'nuxt-property-decorator'

export class InfoBlock {
  img: any
  title!: String
  subtitle!: String

  constructor(img:any, title: String, subtitle: String) {
    this.img = img
    this.title = title
    this.subtitle = subtitle
  }
}

@Component
export default class extends Vue {
  @Prop() value!: InfoBlock
}
