
import { Component, Vue } from 'nuxt-property-decorator'
import InfoBlockItem, { InfoBlock } from './Item.vue'
import svgDelivery from '~/components/svg/delivery.vue'
import svgSupport from '~/components/svg/support.vue'
import svgCredit from '~/components/svg/credit.vue'
import svgSettingth from '~/components/svg/settingth.vue'
@Component({
  components: { InfoBlockItem }
})
export default class extends Vue {
  list = [
    new InfoBlock(
      svgDelivery,
      this.$t('component.info_block.1.title').toString(),
      this.$t('component.info_block.1.subtitle').toString(),
    ),
    new InfoBlock(
      svgSupport,
      this.$t('component.info_block.2.title').toString(),
      this.$t('component.info_block.2.subtitle').toString(),
    ),
    new InfoBlock(
      svgCredit,
      this.$t('component.info_block.3.title').toString(),
      this.$t('component.info_block.3.subtitle').toString(),
    ),
    new InfoBlock(
      svgSettingth,
      this.$t('component.info_block.4.title').toString(),
      this.$t('component.info_block.4.subtitle').toString(),
    ),
  ]
}
