
import {Component, Vue} from 'nuxt-property-decorator'
import Item from './ListItem.vue'
import {categories} from '~/std/Category'

@Component({
  components: {Item}
})
export default class extends Vue {
  isShow = true
  get categories() {
    return categories.list
  }

  refresh() {
    this.$set(this, 'isShow', false)
    this.$nextTick(() => {
      this.$set(this, 'isShow', true)
    })
  }

  mounted() {
    this.$root.$on('changeCategoryAvailability', this.refresh)
  }

  beforeDestroy() {
    this.$root.$off('changeCategoryAvailability', this.refresh)
  }
}
