
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Category from '~/std/Category'

@Component
export default class extends Vue {
  @Prop({ required: true }) category!: Category

  getLink() {
    const link = this.category.getLink({}, null, this)
    return link
  }
}
